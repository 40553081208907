import React, { useContext, useEffect, useRef, useState } from 'react';
import BaseContext from '../components/common/BaseContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SlideOver from '../components/common/SlideOver';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { Client } from '../components/common/types';

import { useParams } from 'react-router-dom';
import Button from '../components/common/Button';
import useRequest from '../hooks/useRequest';
import { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import ClientTable from '../components/Client/ClientTable';

const ClientManagement = () => {
  const { organization, setShowNotification, setLoading, setOrganization, loading } =
    useContext(BaseContext);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const urlParams = useParams();
  const [merchants, setMerchants] = useState<Client[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const currentPageRef = useRef(1);

  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Client>({});

  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { executeRequest: fetchMerchantsData, pending: isMerchantFetching } = useRequest(
    `${apiPaths.GET_MERCHANTS}`,
    [],
  );
  const { executeRequest: postMerchantRequest } = useRequest(`${apiPaths.GET_MERCHANTS}`, {});
  const { executeRequest: updateMerchantRequest } = useRequest(`${apiPaths.UPDATE_MERCHANT}`, {});
  const { executeRequest: inviteMemberRequest } = useRequest(`${apiPaths.SEND_INVITATION}`, {});

  useEffect(() => {
    const fetchData = async () => {
      if (urlParams.orgId) {
        try {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          const org = organizationsResponse?.data.find(
            (org) => org.organizationId === urlParams.orgId,
          );
          setOrganization(org);
          const merchantsParams: RequestParams = {
            queryParams: { 'filter[organizationId]': org.organizationId },
            urlParams: {},
          };
          if (org) await fetchMerchantsData(merchantsParams);
        } catch (error) {
          console.error('Error fetching data:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, []);

  const changeStatusClient = async (client: Client, status: string) => {
    setLoading(true);
    try {
      const updatedClient = _.pick(client, ['name', 'email']);
      const merchantParams: RequestParams = {
        queryParams: {},
        urlParams: {
          merchantId: client.merchantId,
        },
      };
      const updatedMerchantResponse = await updateMerchantRequest(merchantParams, {
        method: 'PUT',
        body: JSON.stringify({ ...updatedClient, status: status }),
      });
      if (updatedMerchantResponse.success) {
        fetchMerchants();
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Client status changed successfully',
        });
      } else
        setShowNotification({
          show: true,
          type: 'failure',
          content: updatedMerchantResponse.errors[0]?.message,
        });
    } catch (error) {
      console.log('Error updating the client', error);
    } finally {
      setLoading(false);
    }
  };

  const updateClient = async (client: Client) => {
    setLoading(true);
    try {
      const updatedClient = _.pick(client, ['name', 'email']);
      const merchantParams: RequestParams = {
        queryParams: {},
        urlParams: {
          merchantId: client.merchantId,
        },
      };
      const updatedMerchantResponse = await updateMerchantRequest(merchantParams, {
        method: 'PUT',
        body: JSON.stringify(updatedClient),
      });
      if (updatedMerchantResponse) {
        setOpen(false);
        setEditMode(false);
        fetchMerchants();
        reset({
          name: '',
          email: '',
        });
      }
      if (updatedMerchantResponse.success)
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Client updated successfully',
        });
      else
        setShowNotification({
          show: true,
          type: 'failure',
          content: updatedMerchantResponse.errors[0]?.message,
        });
    } catch (error) {
      console.log('Error updating the client', error);
    } finally {
      setLoading(false);
    }
  };

  const addClient = async (client: Client) => {
    setLoading(true);
    try {
      const updatedClient = _.pick(client, ['name', 'email']);
      const merchantParams: RequestParams = { queryParams: {}, urlParams: {} };
      const merchantPostedResponse = await postMerchantRequest(merchantParams, {
        method: 'POST',
        body: JSON.stringify({ ...updatedClient, organizationId: organization?.organizationId }),
      });
      if (merchantPostedResponse) {
        setOpen(false);
        fetchMerchants();
      }
      if (merchantPostedResponse.success) {
        inviteMember(client.email, {
          merchantIds: [merchantPostedResponse.data.merchantId],
          warehouseIds: [],
        });
        reset({
          name: '',
          email: '',
          mobileNumber: { number: '', countryCode: '' },
          billingAddress: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: {
              code: '',
              name: '',
            },
            zipCode: '',
          },
        });

        setShowNotification({
          show: true,
          type: 'success',
          content: 'Client created successfully',
        });
      } else
        setShowNotification({
          show: true,
          type: 'failure',
          content: merchantPostedResponse.errors[0]?.message,
        });
    } catch (error) {
      console.log('Error adding the client', error);
    } finally {
      setLoading(false);
    }
  };

  const inviteMember = async (
    email: string,
    invitationData: { merchantIds: string[]; warehouseIds: string[] },
  ) => {
    try {
      const invitationParams: RequestParams = { queryParams: {}, urlParams: {} };
      const invitationResponse = await inviteMemberRequest(invitationParams, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          organizationId: organization.organizationId,
          role: 'client',
          warehouseIds: invitationData.warehouseIds,
          merchantIds: invitationData.merchantIds,
        }),
      });
      if (invitationResponse) setOpen(false);
      if (invitationResponse.success)
        setShowNotification({ show: true, type: 'success', content: 'User invited successfully' });
      else
        setShowNotification({
          show: true,
          type: 'failure',
          content: invitationResponse.errors[0]?.message,
        });
    } catch (error) {
      console.log('Error inviting the user', error);
    }
  };

  const fetchMerchants = async (page: number = 1) => {
    try {
      if (organization) {
        const merchantsParams: RequestParams = {
          queryParams: {
            'filter[organizationId]': organization?.organizationId,
            page: page + '',
            limit: 50 + '', // You can adjust this limit as needed
          },
          urlParams: {},
        };

        const response = await fetchMerchantsData(merchantsParams);

        setMerchants((prevMerchants) => {
          const newMerchants = response.data.filter(
            (merchant: Client) =>
              !prevMerchants?.some(
                (prevMerchant) => prevMerchant.merchantId === merchant.merchantId,
              ),
          );
          return [...prevMerchants, ...newMerchants];
        });

        setHasMore(response.data.length > 0);
        currentPageRef.current = +page + 1;
      }
    } catch (error) {
      console.error('Error fetching merchants:', error);
    }
  };

  useEffect(() => {
    fetchMerchants(1); // Fetch initial data
  }, [organization]);

  const loadMore = () => {
    if (!isMerchantFetching && hasMore) {
      fetchMerchants(currentPageRef.current);
    }
  };

  return (
    <div className='px-8 py-4'>
      <div className='flex justify-between'>
        <div>
          <h1 className='text-[#030229] text-[24px] font-bold'>Client Management</h1>
          <p className='text-[14px]'>Manage your clients</p>
        </div>
        <Button
          onClick={() => {
            reset({
              name: '',
              email: '',
              mobileNumber: { number: '', countryCode: '' },
              billingAddress: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: {
                  code: '',
                  name: '',
                },
                zipCode: '',
              },
            });

            setOpen(true);
          }}
        >
          <div>Add Client</div>
        </Button>
      </div>

      <div>
        <ClientTable
          clients={merchants}
          setEditMode={setEditMode}
          reset={reset}
          setOpen={setOpen}
          changeStatusClient={changeStatusClient}
          fetchMoreData={loadMore}
          hasMore={hasMore}
          isFetching={isMerchantFetching}
        />

        {merchants?.length === 0 && !loading && (
          <div>
            It appears that you don’t have any merchants onboarded. Set up your first merchant by
            clicking on the “Create Merchant” button in the top right corner.{' '}
          </div>
        )}
      </div>

      <SlideOver open={open} setOpen={setOpen}>
        <div className=''>
          <div className='flex items-start justify-between'>
            <Dialog.Title className='text-base font-semibold leading-6 text-gray-900'>
              {editMode ? 'Edit Client' : 'Create Client'}
            </Dialog.Title>
            <div className='ml-3 flex h-7 items-center'>
              <button
                type='button'
                className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-hopstack-blue-500 focus:ring-offset-2'
                onClick={() => setOpen(false)}
              >
                <span className='absolute -inset-2.5' />
                <span className='sr-only'>Close panel</span>
                <XMarkIcon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
          </div>
          <div className='text-[#717679] text-[14px] mb-2'>
            {` Please add the name and the email address of the client. The name can be a business name
             or a person's name.`}
          </div>
        </div>

        <form
          onSubmit={handleSubmit((data) => {
            editMode ? updateClient(data) : addClient(data);
          })}
        >
          <div className='sm:col-span-4'>
            <label
              htmlFor='first-name'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Client Name
            </label>
            <div className='mt-2'>
              <input
                {...register('name', { required: 'Name is required' })}
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
              />
              {errors.name?.type === 'required' && (
                <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                  {errors.name?.message.toString()}
                </p>
              )}
            </div>
          </div>

          <div className='sm:col-span-4'>
            <label
              htmlFor='first-name'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email
            </label>
            <div className='mt-2'>
              <input
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
                autoComplete='email'
                className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
              />
              {errors.email && (
                <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                  {errors.email?.message.toString()}
                </p>
              )}
            </div>
          </div>

          {/* <div className='sm:col-span-4'>
                <label
                  htmlFor='last-name'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Phone Number
                </label>
                <PhoneInput
                  country={'us'}
                  containerClass='mt-2 mr-2 pr-2'
                  enableSearch={true}
                  value={`${mobileNumber?.countryCode + mobileNumber?.number}`}
                  onChange={(value, data: any, event, formattedValue) => {
                    setMobileNumber({
                      countryCode: data.dialCode,
                      number: value.slice(data.dialCode.length),
                    })
                  }}
                />
              </div> */}
          {/*
              <div className='flex gap-2 mt-2 w-full'>
                <div className='sm:col-span-4 w-full'>
                  <label className='block text-sm font-medium leading-6 text-gray-900'>
                    Billing Address 1*
                  </label>
                  <div className='mt-2'>
                    <input
                      {...register('billingAddress.line1', {
                        required: 'Billing Address Line 1 is required',
                      })}
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                    />
                    {errors.billingAddress?.line1 && (
                      <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                        {errors.billingAddress?.line1?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>

                <div className='sm:col-span-4 w-full'>
                  <label className='block text-sm font-medium leading-6 text-gray-900'>
                    Billing Address 2
                  </label>
                  <div className='mt-2'>
                    <input
                      {...register('billingAddress.line2')}
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                    />
                  </div>
                </div>
              </div> */}

          {/* <div className='flex gap-2 mt-2 w-full'>
                <div className='sm:col-span-4 w-full'>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    City*
                  </label>
                  <div className='mt-2'>
                    <input
                      {...register('billingAddress.city', {
                        required: 'Billing Address City is required',
                      })}
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                    />
                    {errors.billingAddress?.city && (
                      <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                        {errors.billingAddress?.city?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>

                <div className='sm:col-span-4 w-full'>
                  <label
                    htmlFor='region'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    State / Province*
                  </label>
                  <div className='mt-2'>
                    <input
                      {...register('billingAddress.state', {
                        required: 'Billing Address State is required',
                      })}
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                    />
                    {errors.billingAddress?.state && (
                      <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                        {errors.billingAddress?.state?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>
              </div> */}
          {/* <div className='flex gap-2 mt-2 w-full'>
                <div className='sm:col-span-4 sm:col-start-1'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium leading-6 text-gray-900'
                  >
                    Country*
                  </label>

                  <div className='mt-2'>
                    <select
                      value={country.code}
                      className='block w-full rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                      onChange={(event) => {
                        // Handle the change event here
                        const selectedCountry = countries.find(
                          (country) => country.code === event.target.value,
                        )

                        console.log('🚀 --------------------------------------------------------🚀')
                        console.log('🚀 ~ ClientManagement ~ selectedCountry:', selectedCountry)
                        console.log('🚀 --------------------------------------------------------🚀')
                        setCountry(selectedCountry)
                      }}
                    >
                      <option value='' disabled>
                        Select a country
                      </option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.billingAddress?.country && (
                      <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                        {errors.billingAddress?.country?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>

                <div className='sm:col-span-4 w-full'>
                  <label className='block text-sm font-medium leading-6 text-gray-900'>Zip*</label>
                  <div className='mt-2'>
                    <input
                      {...register('billingAddress.zipCode', {
                        required: 'Billing Address Zip is required',
                      })}
                      className='block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
                    />
                    {errors.billingAddress?.zipCode && (
                      <p className='text-red-500 text-[12px] opacity-80' role='alert'>
                        {errors.billingAddress?.zipCode?.message.toString()}
                      </p>
                    )}
                  </div>
                </div>
              </div> */}

          <div className='flex gap-2 my-4'>
            <button
              onClick={() => setOpen(false)}
              className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              {/* <ArrowLeftIcon className='w-4 h-4 my-auto' /> */}
              Cancel
            </button>
            <input
              type='submit'
              className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            ></input>
          </div>
        </form>
      </SlideOver>
    </div>
  );
};

export default ClientManagement;
